const NOTIFICATION_AGE = 240000;
const MODAL_AGE = 10000;

export default {
    name: 'Notifier',
    props: {
        // todo
    },
    data: () => ({
        closeTimeoutId: null,
        getResultsTimeoutId: null,
        priceTagsEnabled: false,
        showed: false,
        showed2: false,
        total: 0,
        total2: 0,
    }),
    mounted() {
        this.priceTagsEnabled = (window.features.find( feature => feature.feature_name === 'price tag') || {}).enabled;
        if (this.priceTagsEnabled) {
            setTimeout( () => {
                if (this.permissionsFinded()) {
                    this.getResultsCount();
                    this.getResultsTimeoutId = setInterval( () => {
                        this.getResultsCount();
                    }, NOTIFICATION_AGE)
                }
            }, 3000);
        }
    },
    beforeDestroy() {
        if (this.getResultsTimeoutId) {
            clearInterval(this.getResultsTimeoutId);
        }
    },
    methods: {
        permissionsFinded() {
            return (window.permissions || []).findIndex( permId => permId == 115) > -1;
        },
        getResultsCount() {
            window.notifyFromJournal = false;
            const time = this.getTimestamps();
            window.myAjax('GET', '/price-tag/recognition-results-requiring-verification-count', {
                start_date: time.today,
                end_date: time.month,
            }, this.getResultsCountHandler);
        },
        getResultsCountHandler(error, response) {
            window.notifyFromJournal = false;
            if (!error) {
                const time = this.getTimestamps();
                console.log('getResultsCountHandler', time.today, time.month)
                window.myAjax('GET', '/price-tag/barcode/results-requiring-verification-count', {
                    start_date: time.today,
                    end_date: time.month,
                }, (error2, response2) => {
                    if (!error2) {
                        this.total2 = (response2 || {}).total || 0;
                        if (this.total2) {
                            this.showed2 = true;
                            this.closeTimeoutId = setTimeout( () => {
                                this.showed = false;
                                this.showed2 = false;
                                this.closeTimeoutId = null;
                            }, MODAL_AGE)
                        }
                    }
                    this.manageResultsCount(response);
                });
            }
        },
        getTimestamps() { 
            const today = new Date();
            const twoWeeksAgo = new Date();
            
            twoWeeksAgo.setDate(today.getDate() - 14);

            function formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            }

            const todayString = formatDate(today) + 'T23:59:59';
            const twoWeeksAgoString = formatDate(twoWeeksAgo) + 'T00:00:00';

            // return { todayString, twoWeeksAgoString };

            return { today: todayString, month: twoWeeksAgoString };
        },
        refreshNotifier() {
            this.getResultsCount();
        },
        manageResultsCount(result) {
            const total = (result || {}).total || 0;
            if (total) {
                this.showed = true;
                this.total = total;
                if (!window.notifyFromJournal) {
                    if (this.closeTimeoutId) {
                        clearTimeout(this.closeTimeoutId);
                    }
                    this.closeTimeoutId = setTimeout( () => {
                        this.showed = false;
                        this.showed2 = false;
                        this.closeTimeoutId = null;
                    }, MODAL_AGE)
                }
            } else {
                this.showed = false;
                this.total = 0;
            }
            this.$emit('total', {total: total});
        },
        closeMessage() {
            if (this.closeTimeoutId) {
                clearTimeout(this.closeTimeoutId);
            }
            this.showed = false;
        },
        closeMessage2() {
            if (this.closeTimeoutId) {
                clearTimeout(this.closeTimeoutId);
            }
            this.showed2 = false;
        },
        goToJournal() {
            this.$emit('goToJournal');
        },
        goToJournalBarcode() {
            this.$emit('goToJournalBarcode');
        }
    }
}
