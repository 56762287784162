import ControlPanel from './../../control/panel';
import SensorGraph from './../graph';
import SensorList from './../list';
import TabsGroup from './../../base/tabsGroup';

export default {
    name: 'SensorPage',
    components: {
        ControlPanel,
        SensorGraph,
        SensorList,
        TabsGroup,
    },
    props: {
        activeObject: {
            type: Object,
            default: {}
        }
    },
    data: () => ({
        tabsMode: 'objects',
        tabs: [
            { id: 'objects', caption: 'Проверка объектов' },
        ],
        layout: 3,
        loading: false,
        data: [],
        activeSensor: null,
        sensorsEnabled: false,
    }),
    watch: {
        activeObject: {
            handler() {
                this.activeSensor = null;
            }
        },
        activeSensor: {
            handler() {
                // console.log('this.activeSensor', this.activeSensor)
                if (this.activeSensor) {
                    // todo
                }
            }
        }
    },
    mounted() {
        this.sensorsEnabled = (window.features.find( feature => feature.feature_name === 'sensors') || {}).enabled;
        if (this.sensorsEnabled) {
            this.tabs.push(
                { id: 'devices', caption: 'Устройства' },
            )
        }
    },
    methods: {
        pingObject() {
            window.myAjax('GET', `/api/camera/object/ping?object_id=${this.activeObject.id}`, {}, (error, response) => {
                if (!error) {
                    window.myAlert(response.message);
                } else {
                    window.myAlert('Функционал проверки недоступен. Попробуйте позже.');
                }
            })
        },
        tabsHandler(mode) {
            this.tabsMode = mode;
        },
        layoutHandler(data) {
            this.layout = data;
        },
        sensorHandler(data) {
            this.activeSensor = data;
        },
        cameraHandler(data) {
            this.$emit('cameraSelected', data);
            // console.log(`go to camera ${data}`);
        },
        selectTemp(camera_id) {
            this.$refs['SensorList'].selectTemp(camera_id);
        }
    }
}
