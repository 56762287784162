// import { message } from '../../../components/Utils/modal';
import NeuralEditor from './../editor';
import { PERIODS, CRON_DAYS } from './../utils';
import CheckboxNew from './../../base/checkboxNew';
import ModalNew from './../../base/modalNew';
// import NeuralTaskCard from './../taskCard';
import NeuralTaskCard from './../setting';

const STATUSES = {
    'starting': 'Запущен',
    'pending': 'В ожидании работы',
    'running': 'В работе',
    'stopped': 'Остановлен',
    'sleeping': 'В ожидании',
    'suspended': 'На паузе'
}

const MODULE_NAMES = {
    'smoker_detection': 'Курильщики',
    'queue_detection': 'Наличие очереди',
    'absence_and_queue': 'Очередь и отсутствие продавца',
    'uniform_detection': 'Контроль шапок',
    'gloves_detection': 'Контроль перчаток',
    'cleaning_detection': 'Уборка помещений',
    'human_count': 'Подсчёт посетителей',
    'absence_in_zone': 'Отсутствие продавца/кассира',
    'license_plate_detection': 'Распознование автономеров',
    'first_in_detection': 'Контроль времени открытия',
    'last_out_detection': 'Контроль времени закрытия',
    'bac_treatment': 'Бакобработка',
    'equipment_detection': 'Распознавание инвентаря',
}

export default {
    name: 'NeuralList',
    components: {
        NeuralEditor,
        CheckboxNew,

        NeuralTaskCard,
        ModalNew,
    },
    props: {
        limit: {
            type: Number,
            default: 10
        },
        page: {
            type: Number,
            default: 0
        },
        selectedObjects: {
            type: Array,
            default: []
        },
        selectedCameras: {
            type: Array,
            default: []
        },
        selectedTypes: {
            type: Array,
            default: []
        },
        inline: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: {
                object: 0,
                cam: 0
            }
        },
        selecting: {
            type: Boolean,
            default: false
        },
        selectedTasks: {
            type: Array,
            default: []
        }
    },
    watch: {
        limit: {
            handler() {
                this.getTasks();
            },
        },
        page: {
            handler() {
                this.getTasks();
            },
        },
        selectedObjects: {
            handler() {
                this.getTasks();
            },
        },
        selectedCameras: {
            handler() {
                this.getTasks();
            },
        },
        selectedTypes: {
            handler() {
                this.getTasks();
            },
        },
    },
    data: () => ({
        editorShowed: false,
        _tasks: [],
        tasks: [],
        loading: false,
        weekdays: [
            { id: 1, caption: 'Пн', title: 'Понедельник' },
            { id: 2, caption: 'Вт', title: 'Вторник' },
            { id: 3, caption: 'Ср', title: 'Среда' },
            { id: 4, caption: 'Чт', title: 'Четверг' },
            { id: 5, caption: 'Пт', title: 'Пятница' },
            { id: 6, caption: 'Сб', title: 'Суббота' },
            { id: 0, caption: 'Вс', title: 'Воскресенье' },
        ],
        names: {
            'run_queue_detection': 'Наличие очереди в заданной области',
            'run_absence_in_zone': 'Отсутствие сотрудника в заданной области'
        },
        positions: {},
        selectedTask: null,

        activeCam: null,
        object: null,
        availableObjects: [],
        availableTypes: [],

        showTaskPanel: false,
        statusChanging: false,
    }),
    mounted() {
        this.getTasks();
    },
    methods: {
        check(taskId) {
            let updatedValue = [...this.selectedTasks];
            const findedIndex = updatedValue.findIndex(task => task == taskId);
            if (findedIndex < 0) {
                updatedValue.push(taskId);
            } else {
                updatedValue.splice(findedIndex, 1);
            }
            this.$emit('updateSelected', updatedValue);
        },
        getFilteredTasks() {
            return this.tasks.map(task => task.id);
        },
        editZone(task) {
            this.activeCam = task.cam;
            this.object = task.object;
            this.selectedTask = task;
            this.showTaskPanel = true;
        },
        closeEditor() {
            this.showTaskPanel = false;
            this.getTasks();
        },
        getPositions(data) {
            const self = this;
            window.myAjax('GET', '/ptz/positions', {
                camera_id: data.id
            }, function(error, response) {
                self.getPositionsCallback(error, response, data.id)
            })
        },
        getPositionsCallback(error, response, object_id) {
            if (!error) {
                this.positions[object_id] = response.constructor.name === 'Array' ? response : [];
            }
        },
        filter() {
            this.tasks = [];
            let filteredTasks = [...this._tasks];
            if (this.selected.object) {
                filteredTasks = filteredTasks.filter( task => this.selected.object == task.object_id);
            }
            if (this.selected.cam) {
                filteredTasks = filteredTasks.filter( task => this.selected.cam == task.camera_id);
            }
            if (this.selected.type) {
                filteredTasks = filteredTasks.filter( task => this.selected.type == task.task_type);
            }
            filteredTasks.forEach(task => {
                this.tasks.push(task);
            });
            this.setPage();
        },
        setPage() {
            this.tasks.forEach(task => {
                task.img.src = '/preview/last_img?camera_id=' + task.camera_id + '&time=' + new Date();
                task.img.addEventListener('load', function() {
                    const canvas = document.getElementById('canvas' + task.id);
                    if (!canvas) {
                        return;
                    }
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(this, 0, 0, this.width, this.height);
                    ctx.fillStyle = "#bb000033";
                    ctx.beginPath();
                    ctx.moveTo(task.zone[0].x, task.zone[0].y);
                    for (let i = 1; i < task.zone.length; i++) {
                        const point = task.zone[i];
                        ctx.lineTo(point.x, point.y);
                    }
                    ctx.closePath();
                    ctx.fill();
                })
            })
        },
        getDateStr(time) {
            return time.toLocaleDateString();
        },
        getScheduleHours(schedule, start, stop) {
            console.log('schedule', schedule)
            let result = [];
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            const startTime = new Date(start);
            startTime.setHours(startTime.getHours() + diff);
            const lastTime = new Date(stop);
            lastTime.setHours(lastTime.getHours() + diff);
            result.push(this.getFormatValue(startTime.getHours()));
            result.push(this.getFormatValue(lastTime.getHours()));
            result.push(this.getFormatValue(startTime.getMinutes()));
            result.push(this.getFormatValue(lastTime.getMinutes()));
            return result;
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },
        getWeekDays(schedule) {
            if (schedule === '') {
                return {}
            }
            let periods = schedule.split(/\s/);
            if (periods[4] === '*') {
                const weekDays = {};
                for (let key in CRON_DAYS) {
                    weekDays[key] = true;
                }
                return weekDays;
            }
            let days = periods[4].split(',');
            const weekDays = {};
            days.forEach(dayKey => {
                weekDays[dayKey] = true;
            });
            return weekDays;
        },
        getPeriod(schedule) {
            if (schedule === '') {
                return '-'
            }
            const periods = schedule.split(/\s/);
            let key = '';
            if (periods[0].indexOf('/') > -1) {
                key = 'm' + periods[0].split(/\//)[1];
            } else if (periods[1].indexOf('/') > -1) {
                key = 'h' + periods[1].split(/\//)[1];
            } else if ((periods[5] || '').indexOf('/') > -1) {
                key = 's' + periods[5].split(/\//)[1];
            }
            return PERIODS[key];
        },
        getObject(object_id) {
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i].id == object_id) {
                    return window.myObjects[i];
                }
            }
            return null;
        },
        getCamera(camera_id) {
            for (let i = 0; i < window.myCams.length; i++) {
                if (window.myCams[i].id == camera_id) {
                    return window.myCams[i];
                }
            }
            for (let i = 0; i < window.user_cameras.length; i++) {
                if (window.user_cameras[i].camera_id == camera_id) {
                    return window.user_cameras[i].camera;
                }
            }
            return null;
        },
        getTasks() {
            this.loading = true;
            window.myAjax('GET', '/ml/tasks', {
                page: this.page,
                limit: this.limit,
                object_ids: JSON.stringify(this.selectedObjects),
                camera_ids: JSON.stringify(this.selectedCameras),
                task_types: JSON.stringify(this.selectedTypes),
            }, this.getTasksCallback);
        },
        getTasksCallback(error, response) {
            this.loading = false;
            console.log('getTasksCallback')
            if (error) {
                window.myAlert(response.message);
            } else {
                // const self = this;
                this._tasks = [];
                this.availableObjects = [];
                for (const [key, value] of Object.entries(response)) {
                    // if (!value.schedule) {
                    //     continue;
                    // }
                    let start;
                    let stop;
                    if (value.start) {
                        start = new Date(value.start.replace(/-/g, '/'));
                        const d = new Date();
                        let diff = d.getTimezoneOffset();
                        start.setTime(start.getTime() + (-diff*60*1000));
                        stop = new Date(value.stop.replace(/-/g, '/'));
                        stop.setTime(stop.getTime() + (-diff*60*1000));
                    }
                    const cam = this.getCamera(value.camera_id);
                    const digressions = this.getDigressions(value.digression_id, value.digression_ids);
                    const obj = this.getObject(value.object_id);
                    if (!cam || !obj) {
                        continue
                    }
                    if (value.status !== 'stopped') {
                        let taskName = MODULE_NAMES[value.task_type] || 'Нейромодуль';
                        if (value.task_type === 'human_count') {
                            if (value.unique) {
                                taskName = 'Подсчёт уникальных посетителей';
                            }
                        }
                        const task = Object.assign({}, value, {
                            id: key,
                            start: start ? new Date(start) : null,
                            stop: stop ? new Date(stop) : null,
                            scheduleHours: this.getScheduleHours(value.schedule || '', value.start, value.stop),
                            period: this.getPeriod(value.schedule || ''),
                            object: obj,
                            cam: cam,
                            weekDays: this.getWeekDays(value.schedule || ''),
                            task_name: taskName,
                            img: document.createElement('img'),
                            src: '/preview/last_img?camera_id=' + value.camera_id,
                            statusStr: STATUSES[value.status] || 'неизвестен',
                            isSuspended: value.status == 'suspended',
                            digressionsName: digressions.filter( d => d ).join(', '),
                        });

                        if (!this.availableObjects.includes(value.object_id)) {
                            this.availableObjects.push(value.object_id)
                        }
                        if (!this.availableTypes.includes(value.task_type)) {
                            this.availableTypes.push(value.task_type)
                        }
                        if (cam.ptz_enabled) {
                            this.getPositions({id: value.camera_id})
                        }
                        this._tasks.push(task);
                    }
                }
                if (this._tasks[0]) {
                    this.$emit('totalUpdated', this._tasks[0].total || 1);
                }
                this.filter();
            }
        },
        getDigressions(id, ids) {
            const result = [];
            if (id) {
                result.push( (window.myDevs.find( digression => digression.id === id) || {}).name );
            } else if (ids) {
                result.push( (window.myDevs.find( digression => digression.id === ids[0]) || {}).name );
                result.push( (window.myDevs.find( digression => digression.id === ids[1]) || {}).name );
            }
            return result;
        },
        stopTask(data) {
            const self = this;
            window.myConfirm('Внимание!', 'Вы уверены, что хотите остановить задачу?', function() {
                self.loading = true;
                window.myAjax('POST', '/ml/tasks/stop', {
                    task_id: data.id
                }, self.stopTaskCallback);
            })
        },
        stopTaskCallback(error, response) {
            if (error) {
                window.myAlert(response.message);
            } else {
                this.getTasks();
            }
        },
        showImg(src) {
            window.myAlertImg('<img style="width: 100%;" src="' + src + '"/>');
        },
        changeStatusHandler(task) {
            if (this.statusChanging) {
                return;
            }
            this.statusChanging = true;
            if (task.isSuspended) {
                this.resumeTask(task.id);
            } else {
                this.pauseTask(task.id);
            }
        },
        resumeTask(taskId) {
            window.myAjax('POST', '/ml/tasks/resume', {
                task_id: taskId
            }, this.changeStatusCallback);
        },
        pauseTask(taskId) {
            window.myAjax('POST', '/ml/tasks/suspend', {
                task_id: taskId
            }, this.changeStatusCallback);
        },
        changeStatusCallback(error, response) {
            this.statusChanging = false;
            if (error) {
                window.myAlert(response.message || 'Ошибка смены статуса');
            } else {
                this.getTasks();
            }
        }
    }
}
