import LeftMiniControlsCams from '../../components/cams/LeftMiniControlsCams';
import Cams from '../../components/cams/Cams';
// import SmartList from '../../components/cams/smartList';
import CreateDev from '../../components/devs/CreateDev';
import DigressionManager from '../../components/digression/manager';
import IZK from '../../components/devs/IZK';
import Reports from '../../components/reports/Reports';
import Contacts from '../../components/contacts/Contacts';
import Calendar from '../../components/calendar/Calendar';
import AnalyticReports from '../../components/reports/AnalyticReports';
import SensorPage from '../../components/sensor/page';
import Template from '../../components/templates/Template';
import Group from '../../components/templates/Group';
import DevArchiveMaterials from '../../components/cams/DevArchiveMaterials';
import { PencilTool } from '../../components/image/_tools/tools/pencilTool';
import { DrawPointTool } from '../../components/image/_tools/tools/drawPointTool'
import { RectangleTool } from '../../components/image/_tools/tools/rectangleTool'
import BaseButton from '../../components/base/button';
import BaseModal from '../../components/base/modal';
import { getRelations } from '../../components/checklist/Utils/userChecklistRelations';
import { getAllChecklists } from '../../components/checklist/Utils/getAllChecklists';
import { create } from '../../components/Utils/guid';
import { message } from '../../components/Utils/modal';
import ChecklistTree from '../../components/checklist/tree';
import ChecklistCard from '../../components/checklist/card';
import NeuralContent from '../../components/neural/content';
import SmartCams from '../../components/smartArchive/cams';


import PricesUser from '../prices/user';

import {requestFullscreen} from '../../plugins/fullScreenUtilities'

export default {
    name: 'CamsMain',
    components: {
        AnalyticReports,
        SensorPage,
        LeftMiniControlsCams,
        Cams,
        // SmartList,
        CreateDev,
        DigressionManager,
        IZK,
        Reports,
        Contacts,
        Calendar,
        Template,
        Group,
        DevArchiveMaterials,
        BaseButton,
        ChecklistTree,
        ChecklistCard,
        NeuralContent,
        BaseModal,
        PricesUser,
        SmartCams
    },
    props: ['selectedObj', 'show_left_menu', 'mode'],
    data: () => ({
        fromFullscreen: false,
        checklistView: 0,
        checklistMode: false,
        showCamsMode: true,
        archive: false,
        colonnada: 3,
        activeCam: null,
        screenShotSrc: null,
        screenShotImgId: null,
        snapshotSelected: {
            img_id: null,
            img_src: null,
            video_src: null,
            name: null,
            type_id: 1,
            cameraName: null,
        },
        snapshots: [],
        chooseDev: false,
        chooseDevFromIZK: false,
        selectedDev: null,
        izk: false,
        reports: false,
        contacts: false,
        analyticReports: false,
        sensor: false,
        calendar: false,
        template: false,
        group: false,
        temp: null,
        fullScreenCam: false,
        startArchiveDev: false,
        isShowMap: false,
        isSmart: false,
        
        image: null,
        scale: 1,
        viewImage: null,
        viewCanvas: null,
        viewCanvasContext: null,
        tool: null,
        tools: [],
        toolsSelectedIndex: 0,
        toolbar: [],
        isMobile: false,
        checklist: null,
        /** Список чек-листов. */
        checklists: [],
        isHidden: false,
        isNeural: false,
        smartAchive: false,
        check_list_logs: {},

        // checklistEnabled: false,
        archiveEnabled: false,
        smartArchiveEnabled: false,
        neuralEnabled: false,
        checklistsEnabled: false,
        priceTagsEnabled: false,
        isSubPreviewmode: false,
    }),
    watch: {
        mode: {
            handler(value) {
                console.log('watch:mode', value)
                if (value === 'cams') {
                    window.$('#objects-list-title').hide();
                    window.$('#template_list_tmp').show();
                    setTimeout( () => {
                        this.checklistOff();
                    }, 100)
                } else if (value === 'checklists') {
                    window.$('#objects-list-title').show();
                    window.$('#template_list_tmp').hide();
                    setTimeout( () => {
                        this.checklistOn();
                        this.$emit('modeChange', {mode: 'checklists'});
                        
                        const leftNavi = document.getElementById('left-navi');
                        if (leftNavi) {
                            const updateEvent = new CustomEvent('update', {
                                detail: {}
                            });
                            leftNavi.dispatchEvent(updateEvent);
                        }
                    }, 100)
                } else if (value === 'monitoring') {
                    if (this.isMobile) {
                        window.toggleMenu();
                    }
                    window.$('#objects-list-title').show();
                    window.$('#template_list_tmp').hide();
                    setTimeout( () => {
                        this.checklistOff();
                    }, 100)
                    // todo
                }
            }
        },
        selectedObj: {
            deep: true,
            handler(value) {
                console.log('watch:selectedObj', value)
                if (this.mode === 'checklists') {
                    this.checklistOn();
                }
            }
        }
    },
    mounted() {
        this.archiveEnabled = (window.features.find( feature => feature.feature_name === 'archive') || {}).enabled;
        this.smartArchiveEnabled = (window.features.find( feature => feature.feature_name === 'smart archive') || {}).enabled;
        this.neuralEnabled = (window.features.find( feature => feature.feature_name === 'ml') || {}).enabled;
        this.checklistsEnabled = (window.features.find( feature => feature.feature_name === 'checklists') || {}).enabled;
        this.priceTagsEnabled = (window.features.find( feature => feature.feature_name === 'price tag') || {}).enabled;
        if (this.neuralEnabled) {
            window.myAjax('GET', '/api/users_settings', {}, (error, response) => {
                if (!error) {
                    const smartSetting = (response || []).find( setting => setting.name === 'default_cameras_mode');
                    const subPreviewSetting = (response || []).find( setting => setting.name === 'default_preview_mode');
                    this.isSmart = smartSetting ? smartSetting.value === 'true' : false;
                    this.isSubPreviewmode = subPreviewSetting ? subPreviewSetting.value === 'true' : false;
                }
            })
        } else {
            this.isSmart = false;
        }
        this.getAllChecklistsCallback = this.getAllChecklistsCallback.bind(this);
        this.checklistOff = this.checklistOff.bind(this);
        this.hideChecklistTree = this.hideChecklistTree.bind(this);
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
            this.colonnada = 2;
        }
        window.resizeScrollingContent();
        window.addEventListener('resize', function() {
            window.resizeScrollingContent();
        });
        setTimeout(() => {
            if (this.checklistsEnabled && this.permissionsFinded()) {
                getAllChecklists(function() { return; }, true)
            }
        }, 500)
        this.chooseDev = false;
        this.checklistOff();
        document.getElementById('dsv-cams-main').addEventListener('checklistOff', () => {
            this.checklistOff();
        });
        document.getElementById('dsv-cams-main').addEventListener('checklistRepeat', () => {
            this.checklistRepeat();
        });        
    },
    methods: {
        permissionsFinded() {
            const userId = window.getCookie('userId');
            const permissions = ((window.myUsers || []).find( (user) => user.id == userId) || {}).permissions;
            return (permissions || []).findIndex( (permission) => permission == '1009') > -1;
        },
        toggledLeftMiniControlsCams(value) {
            this.isHidden = value;
        },
        showCams(mode) {
            this.showCamsMode = !mode;
        },
        showMap() {
            this.isShowMap = !this.isShowMap;
        },
        setColonnada(colonnada) {
            this.colonnada = colonnada
        },
        showTemplate(template) {
            if (template==null)
                template = {
                    id: null,
                    name: '',
                    is_default: 0
                }
            this.temp = template
            this.showContent('Template')
        },
        showGroup(group) {
            if(group==null)
                group = {
                    id: null,
                    name: '',
                    users_id: []
                }
            this.temp = group
            this.showContent('Group')
        },
        showContent(content_punkt, data) {
            if (data) {
                window.checkdata = data;
            } else {
                window.checkdata = null;
            }
            this.chooseDev = false
            this.chooseDevFromIZK = false
            this.izk = false
            this.reports = false
            this.contacts = false
            this.analyticReports = false
            this.sensor = false
            this.calendar = false
            this.template = false
            this.group = false
            this.startArchiveDev = false
            // this.archive = false
            this.isNeural = false
            switch(content_punkt) {
                case 'IZK':
                    this.izk = true
                    break
                case 'Reports':
                    this.reports = true
                    break
                case 'Contacts':
                    this.contacts = true
                    break
                case 'Calendar':
                    this.calendar = true
                    break
                case 'Template':
                    this.template = true
                    break
                case 'AnalyticReports':
                    this.analyticReports = true
                    break
                case 'Neural':
                    this.isNeural = true
                    break
                case 'Sensor':
                    this.sensor = true
                    break
                case 'Group':
                    this.group = true
                    window.setTimeout(this.refreshGroupUsers, 100)
                    break
                default:
                    window.setTimeout(this.showObjectCams, 100)
                    break
            }
        },
        checklistCaptionClick() {
            if (this.checklistView !== 1) {
                this.checklistView = 1;
            }
            setTimeout( function() {
                if (document.getElementById('dsv-checklist-tree')){
                    const resetEvent = new CustomEvent('reset', { detail: {readOnly: false} });
                    document.getElementById('dsv-checklist-tree').dispatchEvent(resetEvent);
                }
            }, 200);
        },
        refreshGroupUsers() {
            this.$refs['GroupRef'].refreshUsers()
        },
        setActiveCam(cam) {
            if(cam) {
                this.activeCam = cam
            } else {
                this.activeCam = null
            }
        },
        archiveVideo(flag) {
            this.smartAchive = false;
            this.archive = flag;
            this.smartAchive = false;
        },
        archiveVideoOut() {
            if (this.smartAchive) {
                const showMultiCams = this.$refs['CamsRef'].getSmartStatus();
                const allSmartRecordsLength = this.$refs['CamsRef'].getAllSmartRecordsLength();
                console.log('showMultiCams', showMultiCams);
                if (allSmartRecordsLength && !showMultiCams) {
                    this.$refs['CamsRef'].cancelSelectingRecord();
                } else {
                    this.archive = false;
                    this.smartAchive = false;
                    this.startArchiveDev = false;
                }
            } else {
                this.archive = false;
                this.smartAchive = false;
                this.startArchiveDev = false;
            }
        },
        archiveVideoSmart2(flag) {
            if (flag) {
                this.isSmart = true;
            } else {
                this.isSmart = false;
            }
        },
        archiveVideoSmart(flag) {
            this.archive = false;
            this.smartAchive = false;
            if (flag) {
                this.isSmart = true;
            } else {
                this.isSmart = false;
            }
        },
        showObjectCams() {
            this.showObjectCard(this.selectedObj)
        },
        modeChangeHandler(data) {
            this.$emit('modeChange', data);
        },
        showCamOnLink(event) {
            window.$('.dsv-nav__item').removeClass('active');
            window.$('.dsv-nav__item[name=MainCams]').addClass('active');
            this.selectedObj = event.object;
            this.showContent();
            const abc = this;
            setTimeout(function() {
                abc.$refs['CamsRef'].startCam(event.cam)
            }, 120)
        },
        showObjectCard(obj) {
            if(this.calendar) {
                this.$refs['CalendarRef'].setObject(obj)
            } else {
                if (this.$refs['CamsRef']) {
                    this.$refs['CamsRef'].showObjectCams(obj);                    
                }
                if(this.startArchiveDev) {
                    // выключить архивы
//                    this.$refs['LeftMiniControlsRef'].archiveVideo()
                    // выключить создание отклонений
                    this.$refs['LeftMiniControlsRef'].cancelCreateArchiveDev()
                }
            }
        },
        showTemplateCams(template) {
            this.$refs['CamsRef'].showObjectCams(template)
        },

        createScreenShort() {
            let dataURI = null;
            let name = `Скриншот от ${this.dateStr()}`;
            if (window.archiveDate) {
                const d = new Date();
                let diff = d.getTimezoneOffset() / -60;
                let time = new Date(window.archiveDate);
                time.setHours(time.getHours() + diff);                
                name = `Скриншот от ${time.getFullYear()} ${('0' + (time.getMonth() + 1)).slice(-2)} ${('0' + time.getDate()).slice(-2)} 
                    ${time.toLocaleString('ru', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    })}`;
            }

            if (this.isSmart) {
                dataURI = this.$refs['CamsRef'].getCurrentRecordURI();
            } else if (this.isMobile) {
                let cam = this.$refs['CamsRef'].getObjectCam(this.activeCam.id);
                if (cam !== null) {
                    dataURI = cam.newImage.src;
                }
                else {
                    message('Что-то пошло не так. Выберите активную камеру заново и кликните "Создать отклонение"')
                    return;
                }
            } else {
                let isImage = !!window.$('.hls-video-loader-img')[0];
                let video = window.$('video:first')[0] || window.$('.hls-video-loader-img')[0];
                if (!video) { 
                    message('Выберите активную камеру.')
                    return;
                }
                let target = video || window.$('.hls-video-loader-img')[0];
                let transform = target.style.transform.split(/(\(|\)|px)/) || [];
                let scale = parseFloat(transform[2]) || 1;
                let xW = parseFloat(transform[6]) || 0;
                let yH = parseFloat(transform[12]) || 0;
                let width = video.videoWidth || video.clientWidth || window.$('.hls-video-loader-img')[0].width;
                let height = video.videoHeight || video.clientHeight || window.$('.hls-video-loader-img')[0].height;
                let xPerc = xW / document.getElementById('hls-video-container').clientWidth * 100;
                let yPerc = yH / document.getElementById('hls-video-container').clientHeight * 100;
                let canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(isImage ? window.$('.hls-video-loader-img')[0] : video,
                    - (50 - (50 / scale + xPerc)) / 100 * width * scale,
                    - (50 - (50 / scale + yPerc)) / 100 * height* scale,
                    canvas.width * scale,
                    canvas.height * scale
                );
                dataURI = canvas.toDataURL('image/jpeg');
            }

            this.snapshotSelected = {
                type_id: 1,
                img_src: dataURI,
                name: name,
                cameraName: (this.activeCam || {}).name,
            };
            this.setData(dataURI);
            window.setTimeout(this.showScreenShotModal, 100);
        },
        hideChecklistTree() {
            this.checklistView = 2;
        },
        showScreenShotModal() {
            if (window.checklistsMode && window.checklistCardPoint) {
                window.wrongChecklistDevs = false;
                var resultDevs = [];
                const digressions = window.checklistCardPoint.available_digressions;
                window.myDevs.filter( item => {
                    return !!digressions.find(dig => dig.digression_id === item.id);
                }).forEach( (item) => {
                    this.getParents(item).forEach( (res) => {
                        if (!resultDevs.find(dev => dev.id === res.id)) {
                            resultDevs.push(res);
                        }
                    });
                });
                if (!resultDevs.length) {
                    message('К данному пункту чек-листа не привязаны отклонения. Обратитесь к вашему Администратору платформы.');
                    window.wrongChecklistDevs = true;
                    setTimeout( function() {
                        const resetEvent = new CustomEvent('reset', { detail: {readOnly: false} });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(resetEvent);
                        const updateEvent = new CustomEvent('update', { detail: { id: this.checklist.id, break: true } });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                    });
                    return;
                }
            }
            window.$('#modalScreenShot').modal('show')
        },
        showTag() {
            window.$('#modalScreenShot').modal('show');
            this.resetClickHandler();
        },
        closeScreenShotModal() {
            window.fromArchive = false;
            if (this.isSmart || this.archive) {
                window.fromArchive = true;
            }
            if (this.fromFullscreen) {
                this.$refs['CamsRef'].cancelCreateSnap()
            }
            window.$('#modalScreenShot').modal('hide');
            this.fromFullscreen = false;
        },
        sendImageCallback(error, response) {
            if(error) {
                message(response.message)
            } else {
                this.closeScreenShotModal()
                this.snapshotSelected.img_id = response.id
                this.snapshotSelected.img_src = response.link
                window.fromArchive = false;
                if (this.isSmart || this.archive) {
                    window.fromArchive = true;
                }
                // it is necessary to save an img link to digression
                this.snapshots = []
                this.snapshots.push(this.snapshotSelected)

                this.chooseDev = true
            }
        },
        sendImage(dataURI) {
            let url = '/preview/snapshot',
                method = 'POST';
            window.myAjax(method, url, {image: dataURI}, this.sendImageCallback)
        },
        createDev1() {
            if (!this.archive && !this.smartAchive && !this.isSmart) {
                window.archiveDate = null;
            }
            this.selectedObj = this.getObject(this.activeCam.object_id)
            this.sendImage(this.gateImageDataURL());
        },
        cancelCreateArchiveDev() {
            this.startArchiveDev = false
            this.fullScreenArchive()
        },

        createSnap() {
            this.fromFullscreen = true;
            this.createDev(false);
        },

        createDev(archive_flag) {            
            window.handDigression = false;
            if(this.activeCam) {
                if(archive_flag) {
                    this.$refs['LeftMiniControlsRef'].showCreateDev()
                    this.startArchiveDev = true
                    this.snapshots = []
//                    this.$refs['CamsRef'].fullScreenArchive()
                        window.$('video:first').css('height', 'auto')
                        this.fullScreenArchive()
                } else

                    this.createScreenShort()
                //    this.$refs['CamsRef'].createFrontSnapshot()
            } else {
                message('Сначала активируйте видео какой-нибудь камеры')
            }
        },
        neural() {
            this.$refs['CamsRef'].showNeuralListF();
        },
        /*
            Полноэкранное видео
        */
        fullScreenVideo() {
            if (this.activeCam) {
                if(requestFullscreen(document.getElementById(this.activeCam.id))) {
                  // eslint-disable-next-line no-undef
                    $('.video-container>video').removeClass('framed_video').addClass('fullscreen_video')
                }
            } else {
                message('Сначала активируйте видео какой-нибудь камеры')
            }
        },
//         fullScreenVideo() {
//             if(this.activeCam) {
//                 this.fullScreenCam = !this.fullScreenCam
// //                window.fullScreenCam(this.fullScreenCam)
//                 if(this.fullScreenCam) {
//                     window.$('.cams').addClass('my_hide');
//                     window.$('.cams.active_cam_box').removeClass('my_hide').addClass('col-12');
//                     //$('.cams.active_cam_box video').removeClass('active_cam');
//                 } else  {
//                     window.$('.cams').removeClass('my_hide col-12');
//                     //$('.cams.active_cam_box video').addClass('active_cam');
//                 }
//
//             } else {
//                 message('Сначала активируйте видео какой-нибудь камеры')
//             }
//         },

            fullScreenArchive() {
                if (this.activeCam) {
                    this.fullScreenCam = !this.fullScreenCam
                    if (this.fullScreenCam) {
                        window.$('.cams').addClass('my_hide')
                        window.$('.cams>div#' + this.activeCam.id).parent().removeClass('my_hide').addClass('col-12')
                        //window.$('.cams.active_cam_box video').removeClass('active_cam');
                    } else {
                        let active_cam = window.$('video:first')
                        active_cam.css('height', 'auto')
                        window.$('.cams').removeClass('my_hide col-12')
                        //window.$('.cams.active_cam_box video').addClass('active_cam');
                    }
                } else {
                    message('Сначала активируйте видео-архив какой-нибудь камеры')
                }
            },
            goToCam() {
                if (this.izk) {
                    if (window.afterDelete) {
                        window.afterDelete = false;
                        setTimeout(() => {
                            this.$refs['IZKJournal'].getDevs();
                        }, 50)
                    }
                    this.chooseDevFromIZK = false;
                }
                else {
                    this.archive = false;
                    this.smartAchive = false;
                    this.startArchiveDev = false;
                    this.cancelDev();
                }
            },
            goToCamHandler() {
                
                // if (!window.wrongChecklistDevs) {
                //     if (document.getElementById('dsv-checklist-tree')) {
                //         const updateEvent = new CustomEvent('next', { detail: {} });
                //         document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                //     }
                // }
                this.goToCam();
            },
            selectDev(dev) {
                // this.chooseDev = true
                this.chooseDevFromIZK = true;
                const cam = this.getCamById(dev.camera_id);
                const obj = this.getObjById(dev.object_id);
                this.activeCam = cam;
//                this.selectedObj = this.getObject(this.activeCam.object_id)
                this.selectedObj = obj;
                //this.snapshot
                this.selectedDev = dev; 
                window.setTimeout(this.showDev, 0)
            },
            getCamById(id) {
                for (let i = 0; i < window.myCams.length; i++) {
                    const cam = window.myCams[i];
                    if (cam.id == id) {
                        return cam;
                    }
                }
                return null;
            },
            getObjById(id) {
                for (let i = 0; i < window.myObjects.length; i++) {
                    const obj = window.myObjects[i];
                    if (obj.id == id) {
                        return obj;
                    }
                }
                return null;
            },
            showDev() {
                this.$refs['CreateDevRef'].showDevCard(this.selectedDev);
            },
        getObject(obj_id) {
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].id==obj_id)
                    return window.myObjects[i]
            return null
        },

        dateStr() {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(),

                options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
//              weekday: 'long',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'//,
                    //second: 'numeric'
                },
                date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        },
        transferSnaps(snapshotsObj) {
            this.snapshots = snapshotsObj.snapshots
            if (snapshotsObj.flag) {
                window.fromArchive = false;
                if (this.isSmart || this.archive) {
                    window.fromArchive = true;
                }
                this.chooseDev = true
            }
        },
        createArchiveDev() {
            this.startArchiveDev = false
            this.$refs['CamsRef'].transferSnapsUp()
        },
        cancelTransferSnaps() {
            this.startArchiveDev = true
            this.$refs['LeftMiniControlsRef'].cancelTransferSnaps()
        },
        showPreview(preview) {
            this.snapshotSelected = preview
            window.$('#modalScreenShot').modal('show')
        },
        showFrontSnapshot(preview) {
            this.snapshotSelected = preview
            window.$('#modalScreenShot').modal('show')
        },
        deletePreview(preview_index) {
            this.$refs['CamsRef'].deleteSnapshot(preview_index)
        },
        getTemplates() {
            this.template = false
            this.$emit('getTemplates')
        },
        getGroups() {
            this.group = false
            this.$emit('getGroups')
        },

        cancelDev() {
            this.showContent('default');
            this.checklistView = 1;
            setTimeout( function() {
                if (document.getElementById('dsv-checklist-tree')){
                    const resetEvent = new CustomEvent('reset', { detail: {readOnly: false} });
                    document.getElementById('dsv-checklist-tree').dispatchEvent(resetEvent);
                }
            }, 200);
            if (this.isMobile) {
                setTimeout( () => {
                    if (document.getElementById('dsv-checklist-tree')){
                        const updateEvent = new CustomEvent('update', { detail: {
                            id: this.checklistMode ? this.checklist.id : null,
                            break: false
                        } });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                    }
                }, 100);
            }
        },        
        setData(newSrc) {
            var scope = this;
            this.image = document.getElementById('dsv-imageEditor__view-source');
            this.viewCanvas = document.getElementById('dsv-imageEditor__view-canvas');
            this.viewCanvasContext = this.viewCanvas.getContext('2d');
            this.image.src = newSrc;
            this.image.onload = function() {
                scope.viewCanvas.width = this.naturalWidth;
                scope.viewCanvas.height = this.naturalHeight;
                scope.scale = 725 / this.naturalWidth;
                scope.tools = [];
                scope.tools.push(
                    new DrawPointTool(scope.viewCanvas, scope.viewCanvasContext, scope.scale),
                    new PencilTool(scope.viewCanvas, scope.viewCanvasContext, scope.scale),
                    new RectangleTool(scope.viewCanvas, scope.viewCanvasContext, scope.scale, document.getElementById('dsv-imageEditor__view-canvas-container'))
                )
                scope.toolsSelectedIndex = 1;
                document.getElementById('toolbar-button-1').classList.add('active');
                document.getElementById('toolbar-button-2').classList.remove('active');
                document.getElementById('toolbar-button-0').classList.remove('active');
                scope.tool = scope.tools[scope.toolsSelectedIndex];
            }
            this.viewCanvas.onmousedown = function(event) {
                if (!scope.tool) {
                    return;
                }
                scope.tool.onmousedown(event);
            };
            this.viewCanvas.onmousemove = function(event) {
                if (!scope.tool) {
                    return;
                }
                scope.tool.onmousemove(event);
            };
            this.viewCanvas.onmouseup = function(event) {
                if (!scope.tool) {
                    return;
                }
                scope.tool.onmouseup(event);
            };
        },
        getParents(item) {
            var result = [];
            if (item.parent_id) {
                var parent = window.myDevs.find(dev => dev.id === item.parent_id);
                this.getParents(parent).forEach( (res) => { result.push(res)});
            }
            result.push(item);
            return result;
        },
        createHandDev() {
            this.snapshots = [];
            if (document.getElementById('dsv-checklist-tree')) {
                const updateEvent = new CustomEvent('reset', { detail: {} });
                document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                if (this.isMobile && this.checklist) {
                    setTimeout( () => {
                        const updateEvent = new CustomEvent('update', { detail: { id: this.checklist.id } });
                        document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
                    }, 500);
                }
            }
            window.handDigression = true;
            window.fromArchive = false;
            if (this.isSmart || this.archive) {
                window.fromArchive = true;
            }
            this.chooseDev = true;
        },
        toolClickHandler(index) {
            if (index !== this.toolsSelectedIndex) {
                document.getElementById('toolbar-button-' + this.toolsSelectedIndex).classList.remove('active');
                this.toolsSelectedIndex = index;
                document.getElementById('toolbar-button-' + this.toolsSelectedIndex).classList.add('active');
                this.tool = this.tools[this.toolsSelectedIndex];
            }
        },
        resetClickHandler() {
            this.viewCanvasContext.clearRect(0, 0, this.viewCanvas.width, this.viewCanvas.height);
        },
        gateImageDataURL() {
            const sourceCanvas = document.createElement('canvas');
            const sourceCanvasContext = sourceCanvas.getContext('2d');
            sourceCanvas.width = this.image.naturalWidth;
            sourceCanvas.height = this.image.naturalHeight;
            sourceCanvasContext.drawImage(this.image, 0, 0);
            sourceCanvasContext.drawImage(this.viewCanvas, 0, 0);
            sourceCanvasContext.shadowColor = 'white';
            sourceCanvasContext.shadowOffsetX = 0;
            sourceCanvasContext.shadowOffsetY = 0;
            sourceCanvasContext.shadowBlur = 4;
            sourceCanvasContext.font = '22px Roboto';
            sourceCanvasContext.fillText(this.camSubscribes(this.activeCam), 4, this.image.naturalHeight - 6);
            return sourceCanvas.toDataURL('image/jpeg');
        },
        camSubscribes(cam) {
            let res = ''
            if (cam.flag_company_name)
                res += window.getCookie('company_name') + ' / '
            if (cam.flag_object_name)
                res += this.getObjectName(cam.object_id) + ' / '
            if (cam.flag_cam_name)
                res += cam.name + ' / '
            if (cam.flag_user_id)
                res += window.getCookie('userFio') + ' / '
            if (cam.flag_comment && cam.comment)
                res += cam.comment + ' / '
            if (res.length)
                res = res.substring(0, res.length - 3)
            return res
        },
        getObjectName(obj_id) {
            let obj_name = ''
            for (let i = 0; i < window.myObjects.length; i++)
                if (window.myObjects[i].id == obj_id) {
                    obj_name = window.myObjects[i].name
                    break
                }
            return obj_name
        },
        checklistOn() {
            if (!this.checklistsEnabled) {
                return;
            }
            if (!this.checklist) {
                if (this.selectedObj) {
                    window.selectedObjId = this.selectedObj.id;
                    const params = {
                        params: {
                            object_id: this.selectedObj.id,
                            user_id: window.getCookie('userId')
                        }
                    };
                    window.myAjax('GET', '/api/checklists', {}, (error, response) => {
                        if (error) {
                            window.myAlert(response.message);
                        } else {
                            window.checklists = response.check_lists || [];
                            getRelations(params, this.getAllChecklistsCallback);
                        }
                    });
                    window.$('#objects-list-title').hide();
                    window.$('#template_list_tmp').hide();
                    return;
                } else {
                    if (this.isMobile) {
                        window.toggleMenu();
                    }
                    window.$('#objects-list-title').show();
                    window.$('#template_list_tmp').hide();
                }
            }
        },

        sensorCameraHandler(camera_id) {
            this.showContent()
            this.$emit('changedPage', 'MainCams');
            window.$('.dsv-nav__item').removeClass('active');
            window.$('.dsv-nav__item[name=MainCams]').addClass('active');
            setTimeout( ()  => {
                const camera = window.myCams.find(cam => cam.id === camera_id);
                this.$refs['CamsRef'].startCam(camera)
            }, 200)
        },

        tempHandler(camera_id) {
            this.showContent('Sensor')
            this.$emit('changedPage', 'Sensor');
            window.$('.dsv-nav__item').removeClass('active');
            window.$('.dsv-nav__item[name=Sensor]').addClass('active');
            setTimeout(() => {
                this.$refs['SensorPage'].selectTemp(camera_id);
            }, 200)
        },

        /** Функция обратного вызова при получении списках всех чек-листов. */
        getAllChecklistsCallback(response) {
            this.checklists = [];
            for (let i = 0; i < response.relations.length; i++) {
                const checklist = response.relations[i];
                const index = (window.checklists || []).findIndex( (wc) => wc.id === checklist.checklist_id);
                if (index >= 0) {
                    this.checklists.push(window.checklists[index])
                }
            }
            this.check_list_logs = {};
            this.checklists.forEach( (checklist) => {
                window.myAjax('GET', '/api/checklist-log', {
                    root_id: checklist.id
                }, (error, logResponse) => {
                    if (error) {
                        window.myAlert(logResponse.message);
                    } else {
                        const result_logs = logResponse.check_list_logs;
                        if (result_logs) {
                            const points = this.getPoints(result_logs);
                            // const logs = ;
                            let result = [];
                            const logs = this.getLogs(result_logs).filter( log => {
                                const date = new Date(log.created_at)
                                const now = new Date();
                                let diff = now.getTimezoneOffset()/ -60;
                                date.setHours(date.getHours() + diff);
                                return date.toLocaleString('ru', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                }) === now.toLocaleString('ru', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                })
                            }).filter( log => log.object_id == this.selectedObj.id);
                            points.forEach( pointId => {
                                if (
                                    logs.findIndex( log => log.primary_id == pointId) >= 0
                                ) {
                                    result.push(pointId);
                                }
                            })
                            result = [...new Set(result)];
                            const obj = {};
                            if (points.length === 0) {
                                obj[result_logs.id] = 'нет пунктов' ;
                            } else {
                                obj[result_logs.id] = result.length === 0 ? 'не начат' :
                                    (result.length === points.length ? 'завершен' : 'незакончен');
                            }
                            this.check_list_logs = Object.assign({}, this.check_list_logs, obj);
                        }
                    }
                });
            })
            
            if (!this.checklists.length && !this.haveChild(this.selectedObj)) {
                this.manageChecklistModal('show');
            } else if (this.checklists.length && !this.checklist) {
                this.manageChecklistModal('show');
            } else {
                // window.$('div[node_id='+this.selectedObj.id+']:first').toggleClass('opened');
                // console.log('this.selectedObj', this.selectedObj)
                // $(this).parent().toggleClass('opened');
            }
            
        },
        getLogs(log) {
            if (log.type !== 'point') {
                let childrenLogs = [];
                for (let i = 0; i < log.children.length; i++) {
                    const children = log.children[i];
                    childrenLogs = childrenLogs.concat(this.getLogs(children));
                }
                return childrenLogs;
            } else {
                return log.log;
            }
        },
        getPoints(log) {
            if (log.type !== 'point') {
                let childrenIds = [];
                for (let i = 0; i < log.children.length; i++) {
                    const children = log.children[i];
                    childrenIds = childrenIds.concat(this.getPoints(children));
                }
                return childrenIds;
            } else {
                return log.id;
            }
        },
        haveChild(object) {
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i].parent_id == object.id) {
                    return true;
                }
            }
            return false;
        },
        checklistOff2 () {
            this.manageChecklistModal('hide');
            return;
        },
        checklistRepeat() {
            this.checklistOff();
            setTimeout( () => {
                this.checklistOn();
            }, 30);
        },
        checklistOff() {
            window.checklistCardPoint = null;
            this.checklistMode = false;
            this.showCamsMode = false;
            this.$refs['LeftMiniControlsRef'].setShowCamsMode(true);
            window.$('.dsv-icon_checklist-cams').addClass('dsv-hidden');
            this.checklist = null;
            window.checklistsMode = false;
            this.manageChecklistModal('hide');
            const leftNavi = document.getElementById('left-navi');
            if (leftNavi) {
                const updateEvent = new CustomEvent('update', {
                    detail: {}
                });
                leftNavi.dispatchEvent(updateEvent);
            }
            this.$emit('checklistModeOff');
            window.setTimeout(this.showObjectCams, 100);
            window.$('.dsv-icon_checklist').removeClass('active');
            const updateEvent = new CustomEvent('update', { detail: { id: null, break: true } });
            document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
            // todo понормально сделать!
            window.$('#app').removeClass('dsv-checkmode');
            const templatesTree = document.getElementById('template_list_tmp');
            if (templatesTree) { templatesTree.style.display = 'block'; }
            const searchBlock = document.getElementById('search_block');
            if (searchBlock) { searchBlock.style.display = 'flex'; }
            const objectsHeader = document.getElementById('dsv-leftNavi__header');
            if (objectsHeader) { objectsHeader.style.display = 'block'; }
            const contactBlock = document.querySelector('div[name=Contacts]')
            if (contactBlock) { contactBlock.style.display = 'block'; }
            const reports = document.querySelector('div[name=Reports]');
            if (reports) { reports.style.display = 'block'; }
            const calendar = document.querySelector('div[name=Calendar]');
            if (calendar) { calendar.style.display = 'block'; }
            const analyticReports = document.querySelector('div[name=AnalyticReports]');
            if (analyticReports) { analyticReports.style.display = 'block'; }
            const journal = document.querySelector('div[name=IZK]');
            if (journal) { journal.style.display = 'block'; }
            const neural = document.querySelector('div[name=Neural]');
            if (neural) { neural.style.display = 'block'; }
            window.setTimeout(window.addObjectListeners, 20);
        },
        manageChecklistModal(param) {
            window.$('#dsv-checklist-cams-modal').modal(param);
        },
        checklistClickHandler(options) {
            window.$('.dsv-icon_checklist-cams').removeClass('dsv-hidden');
            this.checklistView = 1;
            this.checklistMode = true;
            window.checklistsMode = true;
            this.checklist = options;
            window.sessionId = create();
            this.manageChecklistModal('hide');
            const contactBlock = document.querySelector('div[name=Contacts]')
            if (contactBlock) { contactBlock.style.display = 'none'; }
            const reports = document.querySelector('div[name=Reports]');
            if (reports) { reports.style.display = 'none'; }
            const calendar = document.querySelector('div[name=Calendar]');
            if (calendar) { calendar.style.display = 'none'; }
            const analyticReports = document.querySelector('div[name=AnalyticReports]');
            if (analyticReports) { analyticReports.style.display = 'none'; }
            const journal = document.querySelector('div[name=IZK]');
            if (journal) { journal.style.display = 'none'; }
            const neural = document.querySelector('div[name=Neural]');
            if (neural) { neural.style.display = 'none'; }
            const updateEvent = new CustomEvent('update', { detail: { id: options.id, log: true } });
            document.getElementById('dsv-checklist-tree').dispatchEvent(updateEvent);
        },
        updateNotifierHandler() {
            this.$emit('updateNotifier');
        }
    }
}