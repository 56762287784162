import Pagination from '../../../components/Pagination';
import PricesJournalList from './../../prices/journalList';
import PricesBarcodeJournal from './../../prices/barcodeJournal';
import { getAllChecklists } from '../../../components/checklist/Utils/getAllChecklists';
import { getChecklistLog } from '../../../components/checklist/Utils/getChecklistLog';
import { getChecklist } from '../../../components/checklist/Utils/getChecklist';
import CheckboxList from '../../../components/base/checkboxList';
import ModalNew from './../../base/modalNew'

export default {
    name: 'IZK',
    components: {
        Pagination,
        PricesJournalList,
        PricesBarcodeJournal,
        CheckboxList,
        ModalNew
    },
    props: ['selectedObj'],
    data: () => ({
        checklist: null,
        checklistLoading: true,
        devsLoading: false,
        checklistPages: [],
        checklistFilter: {
            dateStart: null,
            dateEnd: null,
            object: null,
            page: 0,
            filled: '0' // 0 - все, 1 - заполненные, 2 - не заполненные
        },
        checklistSort: '1',
        checklists: [],
        checklistsLogs: [],
        checklistsLogsFiltered: [],
        allChecklists: [],
        showFilters: false,
        showModalDevs: false,
        devs: [],
        priors: [
            {
                id: 1,
                colorClass: 'selectRed',
                name: 'Высокий'
            },
            {
                id: 2,
                colorClass: 'selectYellow',
                name: 'Средний'
            },
            {
                id: 3,
                colorClass: 'selectGreen',
                name: 'Низкий'
            },
            {
                id: 4,
                colorClass: 'selectWhite',
                name: 'Чек-Лист'
            },
        ],

        adminBaseRole: false,
        selected_role_id: 0,
        base_roles: [],

        rucsupBaseRole: false,
        isMobile: false,
        selected_group_id: 0,
        groups: [],

        selected_object_id: 0,
        objects: [],

        users: [],
        user_id: 0,

        pages: [],
        all_pages: null,
        request: {
            page: 0,
            limit: 10,
            group_id: 0,
            user_id: 0,
            start_date: null,
            end_date: null,
            order_by: 'detect_date', //(‘created_by’ | ‘created_at’ | detect_date),
            order: 'desc', // (‘asc’ | ‘desc’)
            digressions: [],
            objects: []
        },
        searchId: '',
        checklistsEnabled: false,
        priceTagsEnabled: false,
        canShowChecklist: false,
        isChecklistCard: false,
        screenDev: {},

        allDevsSelected: false,
        allObjectsSelected: false,
        allDevsGroupsSelected: false,
        modalDevsHead: '',
        modalObjectsHead: '',
        modalDevsGroupHead: '',
        modalDevs: [],
        objects2: [],
        devsGroups: [],
        selectedDevs: [],
        selectedObjects: [],
        selectedDevsGroups: [],
        devs2: [],
        tab: 0,
        devStatuses: [],
        selectedStatus: 0,
        selectedType: 0,
    }),
    mounted() {
        this.getDevStatuses();
        this.checklistsEnabled = (window.features.find( feature => feature.feature_name === 'checklists') || {}).enabled;
        this.priceTagsEnabled = (window.features.find( feature => feature.feature_name === 'price tag') || {}).enabled;
        window.izkChecklistsReady = false;
        window.izkChecklistsLogsReady = false;
        this.devs2 = window.myDevs;
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        this.getChecklistStatus();
        document.getElementById('dsv-app').classList.add('izk');
        var today = new Date();
        this.request.end_date = this.checklistFilter.dateEnd = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        today.setDate(today.getDate() - 1);
        this.request.start_date = this.checklistFilter.dateStart = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        this.checklistFilter.object = 0;
        window.resizeScrollingContent();
        this.adminBaseRole = true
        this.base_roles = []
        const self = this;
        if ((window.myRoles || []).length) {
            this.setRoles();
        } else {
            setTimeout(function() {
                self.setRoles();
            }, 1200);
        }
        if ((window.myUsers || []).length) {
            this.initFilterUsers()
        } else {
            setTimeout(function() {
                self.initFilterUsers();
            }, 1200);
        }
        if(window.getCookie('userBaseRole')=='Руководитель супервайзеров') {
            this.rucsupBaseRole = true;
            this.groups = window.myGroups;
            this.initFilterUsers2();
        }
        if (window.lastDevs) {
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i] !== undefined && window.myObjects[i].object_type === "object")
                    this.objects.push(window.myObjects[i])
            }
            this.request.object_id = this.selected_object_id
            this.devs = window.lastDevs;
            this.request = window.lastFilter;
            this.checklistSort = window.izk_checklistSort;
            this.selectedType = window.izk_selectedType;
            this.selectedStatus = window.izk_selectedStatus;
            this.searchId = window.izk_searchId;
            this.selected_object_id = window.selected_object_id;
            this.selected_role_id = window.selected_role_id;
            this.showFilters = true;
        }
        if (window.globalFilter) {
            this.showFilters = true;
            this.selectedDevsGroups = window.globalDevsGroup;
            if (typeof window.globalFilter.start_date === 'string') {
                this.request.start_date = window.globalFilter.start_date;
            } else {
                var start = new Date(window.globalFilter.start_date * 1000);
                this.request.start_date = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).slice(-2) + '-' + ('0' + start.getDate()).slice(-2);
            }
            if (typeof window.globalFilter.end_date === 'string') {
                this.request.end_date = window.globalFilter.end_date;
            } else {
                var end = new Date(window.globalFilter.end_date * 1000);
                this.request.end_date = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).slice(-2) + '-' + ('0' + end.getDate()).slice(-2);
            }
            this.request.objects = window.globalFilter.objects;
            for (let i = 0; i < this.request.objects.length; i++) {
                this.selectedObjects.push(this.getObject(this.request.objects[i]).id)
            }
            this.request.digressions = window.globalFilter.digressions;
            for (let i = 0; i < this.request.digressions.length; i++) {
                this.selectedDevs.push(this.getDev2(this.request.digressions[i]).id)
            }
        }
        if (window.isIZKChecklist) {
            this.showChecklistCard(window.isIZKChecklist);
        } else {
            if (!this.devs.length) {
                this.initFilterObjects();
            }
        }
        if (window.journalType === 'monitoring') {
            window.journalType = false;
            this.tabClickHandler(2);
            return;
        } else if (window.journalType === 'monitoring2') {
            window.journalType = false;
            this.tabClickHandler(3);
            return;
        }
        this.tabClickHandler(this.tab);
        // this.devsGroups = []
        // if (this.selectedObjects !== undefined && this.selectedObjects.length > 0) {
        //     for(let i=0; i<window.myDevsToObjects.length; i++) {
        //         for(let j=0; j<this.selectedObjects.length; j++) {
        //             if(window.myDevsToObjects[i].object_id===this.selectedObjects[j]) {
        //                 let deg = this.getRootDev(window.myDevsToObjects[i].digression_id)
        //                 if(deg && this.devsGroups.findIndex(function (obj) { return obj.id === deg.id }) === -1)
        //                     this.devsGroups.push(deg)
        //             }
        //         }
        //     }
        // }
        // else {
        //     for(let i=0; i<window.myDevs.length; i++) {
        //         if (window.myDevs[i].parent_id == null) {
        //             this.devsGroups.push(window.myDevs[i]);
        //         }
        //     }
        // }

    },
    destroyed() {
        window.lastDevs = this.devs;
        window.lastFilter = this.request;
        window.selected_object_id = this.selected_object_id;
        window.selected_role_id = this.selected_role_id;
        window.izk_checklistSort = this.checklistSort;
        window.izk_selectedType = this.selectedType;
        window.izk_selectedStatus = this.selectedStatus;
        window.izk_searchId = this.searchId;
        document.getElementById('dsv-app').classList.remove('izk');
    },
    methods: {
        getDevStatuses() {
            window.myAjax('GET', '/api/digression-statuses', {}, (error, response) => {
                if(error) {
                    window.myAlert(response.message || 'Ошибка получения статусов. Попробуйте позже.');
                } else {
                    this.devStatuses = response.digressionStatuses;
                }
            })  
        },
        togglerFromChecklist() {
            // this.request.from_checklist = !this.request.from_checklist;
            this.getDevs(0);
        },
        check(data) {
            this.selectedDevs = data;
        },
        checkGroups(data) {
            this.selectedDevsGroups = data;
        },
        setRoles() {
            for (let i=0; i<window.myRoles.length; i++) {
                if (window.myRoles[i].parent_id !== null) {
                    this.base_roles.push(window.myRoles[i])
                }
            }
        },
        initFilterObjects() {
            if (window.myObjects === undefined) {
                this.getObjects();
                return;
            }
            this.objects = []
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i] !== undefined && window.myObjects[i].object_type === "object")
                    this.objects.push(window.myObjects[i])
            }
            this.request.object_id = this.selected_object_id
            this.getDevs(0)
        },
        initFilterUsers() {
            this.users = []
            for (let i = 0; i < window.myUsers.length; i++) {
                if (window.myUsers[i].profile != undefined && (this.selected_role_id == 0 || this.userHasRole(window.myUsers[i], this.selected_role_id))) {
                    this.users.push(window.myUsers[i])
                }
            }
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
        },
        initFilterUsers2() {
            this.users = [{
                id: window.getCookie('userId'),
                fio: window.getCookie('userFio')
            }]
            if(this.selected_group_id>0) {
                let group = this.getSelectedGroup()
                if(group)
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id)
                                this.users.push(window.myUsers[i])
            } else {
                for (let k = 0; k < window.myGroups.length; k++) {
                    let group = window.myGroups[k]
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id && this.userNotInUsers(window.myUsers[i]))
                                this.users.push(window.myUsers[i])
                }
            }
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
            this.request.group_id = this.selected_group_id
            this.getDevs(0);
        },
        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else if(user_profile.middle_name)
                res = user_profile.last_name + ' ' + user_profile.first_name + ' ' + user_profile.middle_name
            else
                res = user_profile.last_name + ' ' + user_profile.first_name
            return res
        },
        resetFilter() {
            this.request = {
                page: 0,
                limit: 10,
                group_id: 0,
                user_id: 0,
                start_date: null,
                end_date: null,
                order_by: 'detect_date',
                order: 'desc',
                digressions: [],
                objects: []
            }
            this.selected_object_id = 0;
            this.selected_role_id = 0;
            this.selected_group_id = 0;
            this.allDevsSelected= false;
            this.allObjectsSelected= false;
            this.allDevsGroupsSelected= false;
            this.selectedDevs= [];
            this.selectedObjects= [];
            this.selectedDevsGroups= [];
            var today = new Date();
            this.request.end_date = this.checklistFilter.dateEnd = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            today.setDate(today.getDate() - 1);
            this.request.start_date = this.checklistFilter.dateStart = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            window.globalFilter = this.request;
            window.globalDevsGroup = this.selectedDevsGroups;
            this.searchId = '';
            this.getDevs(0);
        },
        applyFilter() {
            this.request.digressions = [];
            if (this.selectedDevs.length) {
                for (let i = 0; i < this.selectedDevs.length; i++) {
                    this.request.digressions.push(this.selectedDevs[i])
                }
            } else if (this.selectedDevsGroups.length) {
                for (let i = 0; i < this.selectedDevsGroups.length; i++) {
                    const root = window.globalDevsGroup.find( g => this.selectedDevsGroups[i] === g.id);
                    const children = this.getAllChildren(root);
                    for (let j = 0; j < children.length; j++) {
                        this.request.digressions.push(children[j].id)
                    }
                }
            }
            this.request.objects = [];
            for (let i = 0; i < this.selectedObjects.length; i++) {
                this.request.objects.push(this.selectedObjects[i])
            }
            window.globalFilter = this.request;
            window.globalDevsGroup = this.selectedDevsGroups;
            this.getDevs(0);
        },
        closeScreenshot() {
            window.$("#modalViewScreenShot").modal('hide')
        },
        openScreenshot(dev) {
            if (!dev.screenshot_preview.length) {
                return;
            }
            this.screenDev = dev;
            window.$("#modalViewScreenShot").modal('show')
        },
        closeChecklistCard() {
            this.isChecklistCard = false;
            this.checklist = null;
            window.isIZKChecklist = false;
        },
        showChecklistCard(checklist) {
            this.checklist = null;
            if (window.isIZKChecklist) {
                this.setChecklists();
                this.tab = 1;
                this.checklistFilter = window.isIZKChecklistFilter;
                this.setFilteredLogs();
                this.setFilteredLogsPages(window.isIZKChecklistFilter.page);
            }
            window.isIZKChecklist = checklist;
            this.isChecklistCard = true;
            this.checklist = checklist;
        },
        getChecklistStatus() {
            if (window.izkChecklistRoots === false) {
                this.canShowChecklist = false;
                return;
            } else if (window.izkChecklistRoots === undefined || window.izkChecklistRoots === null) {
                setTimeout( () => {
                    this.getChecklistStatus();
                }, 200)
            } else {
                this.canShowChecklist = true;
            }
        },
        tabClickHandler(value) {
            this.tab = value;
            if (value === 1) {
                window.izkChecklistsReady = false;
                window.izkChecklistsLogsReady = false;
                window.izkChecklistsReadyCount = 0;
                window.izkChecklistsLogsReadyCount = 0;
                window.izkChecklists = [];
                window.izkChecklistsLogs = [];
                const end = new Date();
                const now = new Date();
                const start = new Date( now.getTime() - 1000 * 3600 * 24 * 7 );
                start.setHours(0, 0, 0);
                window.myAjax('GET', '/api/checklist-logs', {
                    start: start.getFullYear() + "-" +
                        ("0" + (start.getMonth() + 1)).slice(-2) + "-" +
                        ("0" + start.getDate()).slice(-2) + " " +
                        ("0" + start.getHours()).slice(-2) + ":" +
                        ("0" + start.getMinutes()).slice(-2) + ":" +
                        ("0" + start.getSeconds()).slice(-2),
                    end: end.getFullYear() + "-" +
                        ("0" + (end.getMonth() + 1)).slice(-2) + "-" +
                        ("0" + end.getDate()).slice(-2) + " " +
                        ("0" + end.getHours()).slice(-2) + ":" +
                        ("0" + end.getMinutes()).slice(-2) + ":" +
                        ("0" + end.getSeconds()).slice(-2),
                }, (error, response) => {
                    if (!error) {
                        console.log(response);
                    }
                });
                getAllChecklists(function(response) {
                    window.izkChecklistRoots = response.check_lists;
                    window.izkChecklistRootsLength = Object.keys(window.izkChecklistRoots).length;
                    for (const [key, root] of Object.entries(window.izkChecklistRoots)) {
                        console.log(key);
                        getChecklist({id: root.id}, function(response2) {
                            window.izkChecklists.push(response2.check_list);
                            window.izkChecklistsReadyCount++;
                            if (window.izkChecklistsReadyCount >= window.izkChecklistRootsLength) {
                                window.izkChecklistsReady = true;
                            }
                        });
                        getChecklistLog({id: root.id}, function(response3) {
                            window.izkChecklistsLogs.push(response3.check_list_logs);
                            window.izkChecklistsLogsReadyCount++;
                            if (window.izkChecklistsLogsReadyCount >= window.izkChecklistRootsLength) {
                                window.izkChecklistsLogsReady = true;
                            }
                        });
                    }
                }, false)
                this.checklistLoading = true;
                this.setChecklists();
            } else if (value === 0){
                window.isIZKChecklist = false;
                this.getDevs((this.request || {}).page);
            }
        },
        setDigressionRecordChecked(point) {
            for (let i = 0; i < this.checklist.logs.length; i++) {
                const log = this.checklist.logs[i];
                if (point.id == log.primary_id) {
                    if ((log.digressions || []).length) {
                        window.myAjax('PUT', '/api/digression-record-status', {
                            status_id: 5,
                            id: log.digressions[0].digression_id,
                        }, (error, response) =>{
                            if (error) {
                                window.myAlert(response.message)
                            } else {
                                window.myAjax('PUT', '/api/checklist-log', {
                                    log_id: log.id,
                                    status: 'resolved',
                                }, (error2, response2) =>{
                                    if (error2) {
                                        window.myAlert(response2.message)
                                    } else {
                                        const obj = {};
                                        obj.status = Object.assign({}, this.checklist.status);
                                        obj.status[point.id] = 'resolved';
                                        this.checklist = Object.assign({}, this.checklist, obj);
                                        this.checklistsLogs[this.checklist.id].degCount = this.checklistsLogs[this.checklist.id].degCount - 1;
                                        this.checklistsLogs[this.checklist.id].okCount = this.checklistsLogs[this.checklist.id].okCount + 1;
                                        const index = this.checklistsLogs[this.checklist.id].logs.findIndex( checklog => checklog.id == log.id);
                                        this.checklistsLogs[this.checklist.id].logs[index].status = 'resolved';
                                        this.checklistsLogs[this.checklist.id].status[point.id] = 'resolved';
                                        this.checklistsLogsFiltered = Object.values(this.checklistsLogs);
                                        this.setFilteredLogs();
                                        this.setFilteredLogsPages(0);
                                    }
                                })
                            }
                        })
                    }
                }
            }
        },
        openPointChecklist(point) {
            for (let i = 0; i < this.checklist.logs.length; i++) {
                const log = this.checklist.logs[i];
                if (point.id == log.primary_id) {
                    if ((log.digressions || []).length) {
                        window.isIZKChecklistFilter = this.checklistFilter;
                        this.getDev({id: log.digressions[0].digression_id});
                    }
                }
            }
        },
        setChecklists() {
            if (window.izkChecklistsReady && window.izkChecklistsLogsReady) {
                this.checklistLoading = false;
                if (this.checklists.length) {
                    return;
                }
                this.checklistsLogs = {};
                this.allChecklists = window.izkChecklists;
                const users = {};
                if (window.myUsers) {
                    for (let i = 0; i < window.myUsers.length; i++) {
                        users[window.myUsers[i].id] = window.myUsers[i];
                    }
                }
                for (let i = 0; i < window.izkChecklists.length; i++) {
                    const checklist = window.izkChecklists[i];
                    const children = this.getChecklistPoints(checklist) || [];
                    const pointsAndGroups = this.getChecklistPointsWithGroups(checklist) || [];
                    const childrenLength = children.length;
                    let logs = null;
                    for (let j = 0; j < window.izkChecklistsLogs.length; j++) {
                        const log = window.izkChecklistsLogs[j];
                        if (!log) {
                            break;
                        }
                        if (log.id == checklist.id) {
                            logs = this.getLogs(log) || [];
                            break;
                        }
                    }
                    const dsvLocale = window.getCookie('dsvLocale');
                    const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
                    if (!logs) { // nothing
                    } else {
                    for (let j = 0; j < logs.length; j++) {
                        const log = logs[j];
                        if (!log) {
                            break;
                        }
                        if (!this.checklistsLogs[log.session_id]) {
                            const date = new Date(log.created_at)
                            const d = new Date();
                            let diff = d.getTimezoneOffset()/ -60;
                            date.setHours(date.getHours() + diff);
                            this.checklistsLogs[log.session_id] = {
                                id: log.session_id,
                                idCaption: log.id,
                                points: pointsAndGroups,
                                count: childrenLength,
                                time: log.created_at,
                                dateCaption: date.toLocaleString(short, {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                }),
                                weekdayCaption: date.toLocaleString(short, {
                                    weekday: 'short'
                                }),
                                timeCaption: date.toLocaleString(short, {
                                    hour: 'numeric',
                                    minute: 'numeric'
                                }),
                                rootId: checklist.id,
                                title: checklist.title,
                                caption: checklist.caption,
                                description: checklist.description,
                                object: log.object,
                                object_id: log.object_id,
                                user_id: log.user_id,
                                user: users[log.user_id],
                                logs: [log]
                            };
                        } else {
                            this.checklistsLogs[log.session_id].logs.push(log);
                            if (!this.checklistsLogs[log.session_id].object && log.object) {
                                this.checklistsLogs[log.session_id].object = log.object;
                                this.checklistsLogs[log.session_id].object_id = log.object_id;
                            }
                        }
                    }
                    }
                }

                for (const [_key, _logs] of Object.entries(this.checklistsLogs)) {
                    _logs.status = {}
                    let okCount = 0;
                    let degCount = 0;
                    for (let i = 0; i < _logs.logs.length; i++) {
                        _logs.status[_logs.logs[i].primary_id] = _logs.logs[i].status;
                        if (_logs.logs[i].status === 'ok' || _logs.logs[i].status === 'resolved') {
                            okCount++;
                        } else {
                            degCount++;
                        }
                    }
                    this.checklistsLogs[_key].allCount = okCount + degCount;
                    this.checklistsLogs[_key].degCount = degCount;
                    this.checklistsLogs[_key].okCount = okCount;
                }
                const logsCount = Object.keys(this.checklistsLogs).length;
                const pageCount = Math.floor(logsCount / 10) + (logsCount % 10 === 0 ? 0 : 1);
                for (let i = 1; i < pageCount + 1; i++) {
                    this.checklistPages.push(i)
                }
                this.checklistsLogsFiltered = Object.values(this.checklistsLogs);
                this.setFilteredLogs();
                this.setFilteredLogsPages(0);
            } else {
                const abc = this;
                setTimeout(function() {
                    abc.setChecklists()
                }, 200);
            }
        },
        getPercent(value) {
            return Math.round(value);

        },
        getChecklistPointsWithGroups(checklist) {
            if (checklist.type !== 'point') {
                let points = [];
                if (checklist.type === 'subgroup') {
                    points.push(checklist);
                }
                for (let i = 0; i < checklist.children.length; i++) {
                    const children = checklist.children[i];
                    points = points.concat(this.getChecklistPointsWithGroups(children));
                }
                return points;
            } else {
                return checklist;
            }
        },
        getChecklistPoints(checklist) {
            if (checklist.type !== 'point') {
                let points = [];
                for (let i = 0; i < checklist.children.length; i++) {
                    const children = checklist.children[i];
                    points = points.concat(this.getChecklistPoints(children));
                }
                return points;
            } else {
                return checklist;
            }
        },
        getLogs(log) {
            if (log.type !== 'point') {
                let childrenLogs = [];
                for (let i = 0; i < log.children.length; i++) {
                    const children = log.children[i];
                    childrenLogs = childrenLogs.concat(this.getLogs(children));
                }
                return childrenLogs;
            } else {
                return log.log;
            }
        },
        checklistSortChanged(event) {
            this.checklistSort = event.target.value;
            this.checklistFilter.page = 0;
            this.setFilteredLogs();
            this.setFilteredLogsPages(0);
        },
        checklistFilterChanged(event) {
            if (this.checklistLoading) {
                return;
            }
            if (typeof event === 'number') {
                this.checklistFilter.page = event;
                this.setFilteredLogs();
                this.setFilteredLogsPages(event);
            } else {
                this.checklistFilter.page = 0;
                this.setFilteredLogs();
                this.setFilteredLogsPages(0);
            }
        },
        setFilteredLogsPages(page) {
            const filtered = [];
            for (let i = 10 * page; i < 10 * page + 10; i++) {
                if (this.checklistsLogsFiltered[i]) {
                    filtered.push(this.checklistsLogsFiltered[i]);
                }
            }
            this.checklistsLogsFiltered = [];
            for (let i = 0; i < filtered.length; i++) {
                this.checklistsLogsFiltered.push(filtered[i]);
            }
        },
        setFilteredLogs() {
            const start = new Date(this.checklistFilter.dateStart).setHours(0,0,0,0);
            const end = new Date(this.checklistFilter.dateEnd).setHours(23,59,59,999);
            const entries = Object.values(this.checklistsLogs);
            const filled = this.checklistFilter.filled;
            const sort = this.checklistSort;
            const filtered = entries.filter((value) => {
                const date = new Date(value.time).getTime();
                return date >= start && date <= end;
            }).filter((value) => {
                if (filled === '0') {
                    return true;
                } else if (filled === '1') {
                    return value.logs.length >= value.count
                } else {
                    return value.logs.length < value.count
                }
            }).sort(function(a, b) {
                const date1 = new Date(a.time).getTime();
                const date2 = new Date(b.time).getTime();
                return sort === '1' ? date2 - date1 : date1 - date2;
            });
            this.checklistsLogsFiltered = [];
            for (let i = 0; i < filtered.length; i++) {
                this.checklistsLogsFiltered.push(filtered[i]);
            }
            this.checklistPages = [];
            const logsCount = this.checklistsLogsFiltered.length;
            const pageCount = Math.floor(logsCount / 10) + (logsCount % 10 === 0 ? 0 : 1);
            for (let i = 1; i < pageCount + 1; i++) {
                this.checklistPages.push(i)
            }
        },
        sortChanged(event) {
            const order = event.target.value.split('|')[0] || 'desc';
            this.request.order = order;
            this.getDevs(0);
        },
        togglerHandler(value) {
            if (this.devsLoading) {
                return;
            }
            if (value) {
                this.request.order_by = value;
            } else {
                this.request.order_by = ((this.request.order_by === 'detect_date') ? 'created_at' : 'detect_date');
            }
            this.getDevs(0);
        },
        filterUsers() {
            this.users = []
            for (let i = 0; i < window.myUsers.length; i++) {
                if (window.myUsers[i].profile != undefined && (this.selected_role_id == 0 || this.userHasRole(window.myUsers[i], this.selected_role_id))) {
                    this.users.push(window.myUsers[i])
                }
            }
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
        },
        filterObjects() {
            if (window.myObjects === undefined) {
                this.getObjects()
            }
            this.objects = []
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i] !== undefined && window.myObjects[i].object_type === "object")
                    this.objects.push(window.myObjects[i])
            }
            // this.request.object_id = this.selected_object_id
        },
        getObjects() {
            let data = {},
                url = '/api/objects',
                method = 'GET';
            window.myAjax(method, url, data, this.myCallbackGetObj)
        },
        myCallbackGetObj(error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myObjects = response.objects;
                this.objects = []
                for (let i = 0; i < window.myObjects.length; i++) {
                    if (window.myObjects[i] !== undefined && window.myObjects[i].object_type === "object")
                        this.objects.push(window.myObjects[i])
                }
                this.request.object_id = this.selected_object_id
                this.getDevs(0)
            }
        },
        userHasRole(user, role_id) {
            for (let i = 0; i < user.roles.length; i++) {
                if (user.roles[i] == role_id) {
                    return true
                }
            }
            return false
        },
        userHasBaseRole(user) {
            for (let i = 0; i < user.roles.length; i++)
                if (this.selected_role_id === this.getBaseRoleId(user.roles[i]))
                    return true
            return false
        },
        getBaseRoleId(role) {
            if (role.parent_id == null)
                return role.id
            for (let i = 0; i < window.myRoles.length; i++)
                if (window.myRoles[i].id == role.parent_id)
                    return this.getBaseRoleId(window.myRoles[i])
            return 0
        },

        getPrior(priority_id) {
            let res = {
                name: 'Неопределен',
                color: ''
            }
            for(let i=0; i<this.priors.length; i++) {
                if(this.priors[i].id==priority_id) {
                    res.name = this.priors[i].name
                    res.color = this.priors[i].colorClass
                    break
                }
            }
            return res
        },
        working() {
//            window.myAlert('работаем...')
        },
        dateStr(ts) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            let date = new Date(ts),

                options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
//              weekday: 'long',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric'//,
                    //second: 'numeric'
                },
                date_str = date.toLocaleString(short, options); //  среда, 31 декабря 2014 г. н.э. 12:30:00
            return date_str;
        },

        fio(user) {
            if(user.profile) {
                let res = user.profile.last_name + ' ' + user.profile.first_name
                if(user.profile.middle_name)
                    res += ' ' + user.profile.middle_name
                return res
            } else
                return user.fio
        },
        filterUsers2() {
            this.users = [{
                id: window.getCookie('userId'),
                fio: window.getCookie('userFio')
            }]
            if(this.selected_group_id>0) {
                let group = this.getSelectedGroup()
                if(group)
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id)
                                this.users.push(window.myUsers[i])
            } else {
                for (let k = 0; k < window.myGroups.length; k++) {
                    let group = window.myGroups[k]
                    for (let i = 0; i < window.myUsers.length; i++)
                        for (let j = 0; j < group.users.length; j++)
                            if (group.users[j].user_id == window.myUsers[i].id && this.userNotInUsers(window.myUsers[i]))
                                this.users.push(window.myUsers[i])
                }
            }
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
            this.request.group_id = this.selected_group_id
        },
        getSelectedGroup() {
            for (let k = 0; k < window.myGroups.length; k++)
                if(window.myGroups[k].id==this.selected_group_id)
                    return window.myGroups[k]
            return null
        },
        userNotInUsers(user) {
            for (let k = 0; k < this.users.length; k++)
                if(this.users[k].id==user.id)
                    return false
            return true
        },
        /**
         * @typedef {Object} Status
         * @property {string} name Название статуса.
         * @property {string} className Класс статуса для цветного бейджа.
         */
        /**
         * Получить данные о статусе отклонения.
         * @param { number } statusId Тип статуса.
         * @return { Status } Объект с названием и классом статуса.
         */
        getDevStatus(statusId) {
            let status = {
                name: 'неопределен',
                className: ''
            };
            for (let i = 0; i < this.devStatuses.length; i++) {
                if ( this.devStatuses[i].id === statusId ) {
                    status.name = this.devStatuses[i].name;
                    break;
                }
            }
            status.className = 'dsv-izk__status_' + statusId || 0;
            return status;
        },
        fioCreator(dev, creator_flag) {
            let res = null,
                user = dev.responsible.profile
            if(creator_flag)
                user = dev.creator.profile
            if(user) {
                res = user.last_name + ' ' +
                    user.first_name + ' ' +
                    user.middle_name
            }
            return res
        },
        getTime(dateTime) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            let dt = new Date(dateTime);
            dt.setHours(dt.getHours() + diff);
            let options = {
                    hour: 'numeric',
                    minute: 'numeric'//,
                };
            let date_str = dt.toLocaleString(short, options);
            return date_str;
        },
        getDate(dateTime) {
            const dsvLocale = window.getCookie('dsvLocale');
            const short = dsvLocale.length === 2 ? dsvLocale : 'ru';
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            let dt = new Date(dateTime);
            dt.setHours(dt.getHours() + diff);
            let date_str = dt.toLocaleString(short, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                });
            return date_str;
        },
        timerD(dateTime) {
            let dt = new Date(dateTime),
                now = new Date(),
                remain = parseInt((dt.getTime() - now.getTime())/(1000*24*3600)),
                res = ''
            if (remain) {
                if (remain > 0) {
                    res = 'дней: ' + remain;
                }
            }
            return res
        },
        timerT(dateTime) {
            let dt = new Date(dateTime),
                now = new Date(),
                remain = (dt.getTime() - now.getTime())/1000,
                res = '',
                temp = remain%(24*3600)

            if (temp < 0) {
                res = 'истекло'
                return res
            }
            let hh = '', mm = ''
            if(parseInt(temp/3600)<10)
                hh = '0'
            hh += parseInt(temp/3600)
            if(parseInt((temp%3600)/60)<10)
                mm = '0'
            mm += parseInt((temp%3600)/60)
            res += hh + ':' + mm
            return res
        },
        searchIdChanged() {
            if ((this.searchId || '').length > 0) {
                var today = new Date();
                this.request.end_date = this.checklistFilter.dateEnd = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
                today.setMonth(today.getMonth() - 1);
                this.request.start_date = this.checklistFilter.dateStart = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);    
            }
        },
        getDevs(page) {
            if(this.request.end_date && this.request.start_date>this.request.end_date) {
                window.myAlert('Дата ОТ больше даты ДО')
                return
            }
            let _page = (page || page == 0) ? page : this.request.page;
            // todo
            this.request = Object.assign({}, this.request, {
                page: _page
            });
            const data = Object.assign({}, this.request)
            this.devsLoading = true;
            data.page = _page;
            data.objects = [];
            data.digressions = [];
            for (let index in this.request.objects) {
                data.objects.push(this.request.objects[index])
            }
            for (let index in this.request.digressions) {
                data.digressions.push(this.request.digressions[index])
            }
            data.objects = data.objects.length > 0 ? JSON.stringify(data.objects) : null;
            data.digressions = data.digressions.length > 0 ? JSON.stringify(data.digressions) : null;
            if (this.selectedStatus > 0) {
                data.status_id = this.selectedStatus;
            }
            if (this.selectedType > 0) {
                data.type_id = this.selectedType;
            }
            if ((this.searchId || '').length > 0) {
                data.digression_search = this.searchId;
            }
            window.myAjax('GET', '/api/digressions-records', data, this.myCallbackGetDevs)
        },
        myCallbackGetDevs (error, response) {
            if(error) {
                this.devsLoading = false;
                window.myAlert(response.message)
            } else {
                this.devsLoading = false;
                this.devs = response.digressions
                this.devs.forEach( (dev) => {
                    dev.status = this.getDevStatus(dev.status_id);
                    dev.priorityClassName = 'dsv-izk__priority_' + dev.priority_id;
                });
                let all_pages = 2 + parseInt(response.total / this.request.limit);
                if (response.total % this.request.limit==0) {
                    all_pages--;
                }
                this.pages = [];
                for (let i = 1; i < all_pages; i++) {
                    this.pages.push(i);
                }
            }
        },
        getExcel() {
            if (this.devsLoading) {
                return;
            }
            if(this.request.end_date && this.request.start_date>this.request.end_date) {
                window.myAlert('Дата ОТ больше даты ДО')
                return
            }
            this.request.digressions = [];
            if (this.selectedDevs.length) {
                for (let i = 0; i < this.selectedDevs.length; i++) {
                    this.request.digressions.push(this.selectedDevs[i])
                }
            } else if (this.selectedDevsGroups.length) {
                for (let i = 0; i < this.selectedDevsGroups.length; i++) {
                    const root = window.globalDevsGroup.find( g => this.selectedDevsGroups[i] === g.id);
                    const children = this.getAllChildren(root);
                    for (let j = 0; j < children.length; j++) {
                        this.request.digressions.push(children[j].id)
                    }
                }
            }
            this.request.objects = [];
            for (let i = 0; i < this.selectedObjects.length; i++) {
                this.request.objects.push(this.selectedObjects[i])
            }
            window.globalFilter = this.request;
            window.globalDevsGroup = this.selectedDevsGroups;

            const data = {};
            data.start_date = this.request.start_date;
            data.end_date = this.request.end_date;
            if (this.request.user_id) {
                data.user_id = this.request.user_id;
            }
            if (this.request.order_by) {
                data.order_by = this.request.order_by;
            }
            if (this.request.order) {
                data.order = this.request.order;
            }
            data.objects = [];
            data.digressions = [];
            for (let index in this.request.objects) {
                data.objects.push(this.request.objects[index])
            }
            for (let index in this.request.digressions) {
                data.digressions.push(this.request.digressions[index])
            }
            data.objects = data.objects.length > 0 ? JSON.stringify(data.objects) : null;
            data.digressions = data.digressions.length > 0 ? JSON.stringify(data.digressions) : null;
            data.timezone = window.getCookie('timezone');
            window.myAjax('GET', '/api/digressions-records-export', data, this.myCallbackGetExcel)
        },
        myCallbackGetExcel (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                const url = response.export_file;
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
// the filename you want
                a.download = 'excelIZK.xlsx';
                document.body.appendChild(a);
                a.click();

                
                // var binaryData = [];
                // binaryData.push(response);
                // const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))
                // // const url = window.URL.createObjectURL(response)
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = url;
// the filename you want
                // a.download = 'excelIZK.xlsx';
                // document.body.appendChild(a);
                // a.click();
                // window.URL.revokeObjectURL(url);
                // window.myAlert('your file has downloaded!');
            }
        },
        getDev(dev) {
            window.myAjax('GET', '/api/digressions-record', {
                digression_record_id: dev.id
            }, this.myCallbackGetDev)
        },
        myCallbackGetDev (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                let obj = response.digression
                obj.watchers = response.watchers
                this.$emit('selectDev', obj)
            }
        },

        setUser(user) {
            this.request.user_id = user.id
        },

        showChat(dev) {
            this.$store.dispatch('OPEN_CHAT_BY_ID', {openChat: true, showChatButton: false, openDialog: true, id: dev.room_id})
        },

        openDevsModal() {
            this.devsGroups = []
            if (this.selectedObjects !== undefined && this.selectedObjects.length > 0) {
                for(let i=0; i<window.myDevsToObjects.length; i++) {
                    for(let j=0; j<this.selectedObjects.length; j++) {
                        if(window.myDevsToObjects[i].object_id===this.selectedObjects[j]) {
                            let deg = this.getRootDev(window.myDevsToObjects[i].digression_id)
                            if(deg && this.devsGroups.findIndex(function (obj) { return obj.id === deg.id }) === -1)
                                this.devsGroups.push(deg)
                        }
                    }
                }
            }
            else {
                for(let i=0; i<window.myDevs.length; i++) {
                    if (window.myDevs[i].parent_id == null) {
                        this.devsGroups.push(window.myDevs[i]);
                    }
                }
            }
            
            let localDevs = [];
            if (this.selectedObjects !== undefined && this.selectedObjects.length > 0) {
                for(let i=0; i<window.myDevsToObjects.length; i++) {
                    for(let j=0; j<this.selectedObjects.length; j++) {
                        if(window.myDevsToObjects[i].object_id===this.selectedObjects[j]) {
                            let deg = this.getRootDev(window.myDevsToObjects[i].digression_id)
                            if(deg && localDevs.findIndex(function (obj) { return obj.id === deg.id }) === -1)
                                localDevs.push(deg)
                        }
                    }
                }
            }
            else {
                for(let i=0; i<window.myDevs.length; i++) {
                    if (window.myDevs[i].parent_id == null) {
                        localDevs.push(window.myDevs[i]);
                    }
                }
            }
            let roots = [];
            if (this.selectedDevsGroups.length > 0) {
                for (let i = 0; i < localDevs.length; i++) {
                    for (let j = 0; j < this.selectedDevsGroups.length; j++) {
                        if (this.selectedDevsGroups[j] === localDevs[i].id) {
                            roots.push(localDevs[i]);
                            break;
                        }
                    }
                }
            }
            else {
                for (let i = 0; i < localDevs.length; i++)
                    roots.push(localDevs[i]);
            }

            this.modalDevs = [];
            for(let i=0; i<roots.length; i++) {
                this.modalDevs.push(roots[i]);
                this.modalDevs.push.apply(this.modalDevs, this.getAllChildren(roots[i]));
            }

            if (this.modalDevs.length)
                this.modalDevsHead = 'Выберите отклонения'
            else
                this.modalDevsHead = 'К этому объекту/зоне не привязаны отклонения'

            // clear arrays
            this.allDevsSelected = this.isAllChosen("dev");

            // console.log('openDevsModal', this.modalDevs)
            this.showModalDevs = true;
        },
        openDevsGroupsModal() {
            this.devsGroups = []
            if (this.selectedObjects !== undefined && this.selectedObjects.length > 0) {
                for(let i=0; i<window.myDevsToObjects.length; i++) {
                    for(let j=0; j<this.selectedObjects.length; j++) {
                        if(window.myDevsToObjects[i].object_id===this.selectedObjects[j]) {
                            let deg = this.getRootDev(window.myDevsToObjects[i].digression_id)
                            if(deg && this.devsGroups.findIndex(function (obj) { return obj.id === deg.id }) === -1)
                                this.devsGroups.push(deg)
                        }
                    }
                }
            }
            else {
                for(let i=0; i<window.myDevs.length; i++) {
                    if (window.myDevs[i].parent_id == null) {
                        this.devsGroups.push(window.myDevs[i]);
                    }
                }
            }

            if(this.devsGroups.length)
                this.modalDevsGroupHead = 'Выберите группы отклонений'
            else
                this.modalDevsGroupHead = 'К этому объекту/зоне не привязаны отклонения'

            // clear arrays
            this.allDevsGroupsSelected = this.isAllChosen("devsGroups");

            window.$('#modalDevsGroups').modal('show')
        },
        toggleAllSelection(type) {
            if (type === "objects") {
                this.allObjectsSelected = !this.allObjectsSelected;
                if (this.allObjectsSelected) {
                    this.selectedObjects = []
                    for (let i = 0; i < this.objects2.length; i++){
                        let current = this.objects2[i];
                        this.selectedObjects.push(current.id);
                    }
                }
                else {
                    this.selectedObjects = [];
                }
            } else if (type === "devsGroups") {
                this.allDevsGroupsSelected = !this.allDevsGroupsSelected;
                if (this.allDevsGroupsSelected) {
                    this.selectedDevsGroups = []
                    for (let i = 0; i < this.devsGroups.length; i++){
                        let current = this.devsGroups[i];
                        this.selectedDevsGroups.push(current.id);
                    }
                }
                else {
                    this.selectedDevsGroups = [];
                }
            } else {
                this.allDevsSelected = !this.allDevsSelected;
                if (this.allDevsSelected) {
                    this.selectedDevs = []
                    for (let i = 0; i < this.modalDevs.length; i++){
                        let currentDev = this.modalDevs[i];
                        this.selectedDevs.push(currentDev.id);
                    }
                }
                else {
                    this.selectedDevs = [];
                }
            }
        },
        
        openObjectsModal() {
            this.filterObjects2();
            if (this.objects2.length) {
                this.modalObjectsHead = 'Выберите объекты';
            } else {
                this.modalObjectsHead = 'Нет доступных объектов';
            }
            this.allObjectsSelected = this.isAllChosen("objects");
            window.$('#modalObjects').modal('show')
        },
        
        getObject(obj_id) {
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].id==obj_id)
                    return window.myObjects[i]
            return null
        },

        filterObjects2() {
            if (window.myObjects === undefined) {
                this.getObjects()
            }

            this.objects2 = []
            for (let i = 0; i < window.myObjects.length; i++) {
                if (window.myObjects[i] !== undefined && window.myObjects[i].object_type === "object")
                    this.objects2.push(window.myObjects[i])
            }
        },
        
        closeDevsModal() {
            this.showModalDevs = false;
            this.allDevsGroupsSelected = false;
            this.applyFilter();
        },
        
        closeDevsGroupsModal() {
            window.$('#modalDevsGroups').modal('hide');
            this.allDevsGroupsSelected = false;
            this.applyFilter();
        },
        isChosen(dev, type) {
            let list = [];
            if (type === "objects") {
                list = this.selectedObjects
            } else if (type === "devsGroups") {
                list = this.selectedDevsGroups
            } else {
                list = this.selectedDevs
            }
            return list.find(i => i.id === dev.id) !== undefined;
        },
        isAllChosen(type) {
            let list = [];
            if (type === "objects") {
                list = this.objects2
            } else if (type === "devsGroups") {
                list = this.devsGroups
            } else {
                list = this.modalDevs
            }
            let allChosen = true;
            for (let i = 0; i < list.length; i++) {
                let dev = list[i];
                if (!this.isChosen(dev, type)) {
                    allChosen = false;
                }
            }

            return allChosen;
        },
        getAllChildren(root) {
            if (root.priority_id !== null) {
                return [root];
            }
            else {
                let all_children = [];

                for (let i = 0; i < this.devs2.length; i++) {
                    if (this.devs2[i].parent_id === root.id) {
                        all_children.push.apply(all_children, this.getAllChildren(this.devs2[i]));
                    }
                }
                return all_children;
            }
        },
        closeObjectsModal() {
            window.$('#modalObjects').modal('hide');
            this.allObjectsSelected = false;
            this.applyFilter();
        },
        checkObjects(data) {
            this.selectedObjects = data;
        },
        getDev2(id) {
            for(let i=0; i<window.myDevs.length; i++)
                if(window.myDevs[i].id===id)
                    return window.myDevs[i]
            return null
        },
        getParentDev(dev) {
            for(let i=0; i<window.myDevs.length; i++)
                if(window.myDevs[i].id===dev.parent_id)
                    return window.myDevs[i]
            return null
        },
        getRootDev(id) {
            let dev = this.getDev2(id);
            if (dev) {
                let previousDev = dev;
                while (dev !== null) {
                    previousDev = dev;
                    dev = this.getParentDev(dev);
                }
                return previousDev;
            }
            else {
                return null;
            }
        },
        updateNotifierHandler() {
            this.$emit('updateNotifier');
        }
    }
}
